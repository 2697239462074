.Event {
    padding: 20px;
}
.EventTitle {
    font-size: 15px;
    border-bottom: 1px solid var(--tg-theme-text-color);
    margin-bottom: 10px;
}
.EventName {
    font-size: 18px;
}
.EventTopic {
    font-size: 16px;
    margin-bottom: 10px;
}
.EventData {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.EventData p{
    font-size: 14px;
    font-weight: bold;
    color: var(--tg-theme-text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.EventInfoTileData {
    background-color: rgba(190, 190, 190, 0.788);
    padding: 2.5px 8px;
    border-radius: 15px;
}
.regstart {
    display: flex;
}
.regstart {
    margin-bottom: 0;
}