.timer {
    background-color: rgba(190, 190, 190, 0.788);
    padding: 2.5px 8px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.temerEnd {
    background-color: rgba(190, 190, 190, 0.788);
    padding: 2.5px 8px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: rgb(1, 201, 1) !important;
}