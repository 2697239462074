.buttonToAllEvents {
    width: 100%;
    display: flex;
    text-align: center;
    margin-top: 8px;
    justify-content: center;
    text-decoration: none;
    padding: 10px 0;
    border-radius: 12px;
    color: #0285ff;
    font-weight: bold;
    background-color: rgba(156, 156, 156, 0.5);
    transition: 0.5s;
}
.buttonToAllEvents:hover {
    opacity: 0.7;
}