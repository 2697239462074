.formWraper {
    width: 100%;
    padding: 20px;
}
.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.error {
    text-align: center;
    color: red;
}

.formAlreadyMember {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #c2c2c2c7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    text-align: center;
    color: var(--tg-theme-text-color);
}
.formNotStartedYet {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #c2c2c2c7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    text-align: center;
    color: var(--tg-theme-text-color);
    gap: 20px;
}

.form h1 {
    text-align: center;
    border-bottom: 1px solid var(--tg-theme-text-color);
    font-size: 18px;
}
.FormEventName {
    font-size: 16px !important;
    padding: 10px 0;
}
.form h3 {
    margin-top: 15px;
    font-size: 16px;
}
.errorForm {
    font-size: 14px;
    color: rgb(253, 1, 1);
    padding-left: 10px;
}

.input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
    background: transparent;
    border: 1px solid var(--tg-theme-text-color);
    color: var(--tg-theme-text-color);
    font-weight: 600;
}
input::placeholder {
    font-weight: 500;
}
.input:focus {
    border: 1px solid rgba(39, 167, 231, 0.75);
}

.select {
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
    font-weight: bold;
    background: transparent;
    border: 0;
    background-color: rgb(187, 187, 187);
    color: var(--tg-theme-text-color);
    width: 70px;
    margin-right: 10px;
}
.grade-submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.grade-submit {
    padding-top: 15px;
}
.submit-button {
    background-color: #27A7E7;
    padding: 10px 15px;
    color: white;
    font-weight: bold;
    border: 0;
    outline: none;
    border-radius: 15px;
    font-size: 16px;
    transition: 0.5s;
}
.submit-button:hover {
    opacity: 0.7;
}
.submit-button:disabled {
    background-color: rgba(187, 187, 187, 0.788);
    opacity: 0.7;
}
.DangerPrompt {
    font-size: 14px;
    text-align: center;
    color: var(--tg-theme-text-color);
}
.NotAllowPrompt {
    margin-top: 3px;
    font-size: 10px;
}
.check {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.check h3 {
    margin-top: 0;
}
.checkButtons {
    display: flex;
    gap: 10px;
}
.checkConfirm {
    background-color: rgb(0, 199, 0);
    border: 0;
    border-radius: 15px;
    padding: 10px 15px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: 0.5s;
}
.checkDecline {
    background-color: rgb(214, 1, 1);
    border: 0;
    border-radius: 15px;
    padding: 10px 15px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: 0.5s;
}

.checkConfirm:hover {
    opacity: 0.7;
}

.checkDecline:hover {
    opacity: 0.7;
}
.checkPlaces {
    background-color: rgba(190, 190, 190, 0.788);
    padding: 5px 10px;
    border-radius: 15px;
    text-decoration: underline;
}