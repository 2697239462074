.Menu {
    position: fixed;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    margin: 0;
    background: var(--tg-theme-bg-color);
    padding: 0;
}
.Menu__Opened {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
.Menu__Closed {
    display: none;
}
.menuWraper {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.close_menu-button {
    background-color: gray;
    border: none;
    outline: none;
    padding: 10px 15px;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
}
.menu_title {
    color: var(--tg-theme-text-color);
    margin-bottom: 20px;
}
.navbuttns {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.menuRoute {
    color: var(--tg-theme-text-color);
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
    background-color: rgba(156, 156, 156, 0.5);
    border-radius: 15px;
    padding: 10px;
}
.menuRouteAdmins {
    padding-top: 10px;
    color: var(--tg-theme-text-color);
}