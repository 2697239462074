.AdminSignInPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.AdminSignInInput {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
    background: transparent;
    border: 1px solid var(--tg-theme-text-color);
    color: var(--tg-theme-text-color);
    font-weight: 600;
}
.AdminSignInInput::placeholder {
    font-weight: 500;
}
.AdminSignInInput:focus {
    border: 1px solid rgba(39, 167, 231, 0.75);
}

.AdminSignInButton {
    margin-top: 30px;
    background-color: #27A7E7;
    padding: 10px 15px;
    width: 100%;
    color: white;
    font-weight: bold;
    border: 0;
    outline: none;
    border-radius: 15px;
    font-size: 16px;
    transition: 0.5s;
    margin-bottom: 10px;
}
.AdminSignInButton:hover {
    opacity: 0.7;
}
.AdminSignInButton:disabled {
    background-color: rgba(187, 187, 187, 0.788);
    opacity: 0.7;
}
.AdminSignInError {
    color: rgb(255, 48, 48);
    font-weight: medium;
}
.AdminPanelWelcome {
    text-align: center;
    margin: 10px 0;
}
.AdminFuncPanel {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 25px;
}
.AdminFuncPanelButtons {
    background-color: rgb(215, 214, 214);
    border-radius: 20px;
    padding: 15px;
    color: var(--tg-theme-text-color);
    text-decoration: none;
    text-align: center;
    font-weight: bold;
}
.AdminPanelCreateEvent {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}

.AdminPanelCreateEvent input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
    background: rgb(215, 215, 215);
    border: 1px solid var(--tg-theme-text-color);
    color: var(--tg-theme-text-color);
    font-weight: 600;
}
.AdminPanelCreateEvent input::placeholder {
    font-weight: 500;
}
.AdminPanelCreateEvent input:focus {
    border: 1px solid rgba(39, 167, 231, 0.75);
}
.confirmCodeInput {
    margin-top: 25px !important;
}
.confirmbuttons {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.confirmButton {
    background-color: rgb(0, 209, 0);
    padding: 15px;
    border: 0;
    border-radius: 20px;
    color: white;
    font-weight: bold;
}
.confirmButton:disabled {
    background-color: rgb(192, 192, 192);
}
.rejectButton {
    background-color: rgb(253, 50, 50);
    padding: 15px;
    border: 0;
    border-radius: 20px;
    color: white;
    font-weight: bold;
}
.eventMembers {
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.eventMember {
    background-color: #a5a5a5;
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 5px;
    border-radius: 15px;
    align-items: center;
}
.AdminPanelWatchMembers {
    padding: 10px;
}
.eventMemberButton {
    border: 0;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
    background-color: #b6b6b6;
}
.eventSelecter {
    width: 100%;
    background: rgb(215, 215, 215);
    border: 1px solid var(--tg-theme-text-color);
    color: var(--tg-theme-text-color);
    padding: 10px;
    outline: 0;
    border-radius: 10px;
}