* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans';
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}
.App {
  display: flex;
  flex-direction: column;
}
.container {
  padding: 0 20px;
}
.Version {
  width: 100%;
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
  color: gray;
}
.VLlogo {
  max-width: 25px;
}
.VLlogoBox {
  padding-top: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}