.scaner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--tg-theme-bg-color);
}
.scanerhead {
    box-shadow: 0px 10px 17px 7px rgba(34, 60, 80, 0.39);
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

#qrCodeContainer {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 7px;
    overflow: hidden;
}
.scanerbuttonblock {
    width: 100%;
    padding: 20px;
    box-shadow: 0px -10px 17px 7px rgba(34, 60, 80, 0.39);
}
.eventSelecterScaner {
    background-color: transparent;
    font-weight: bold;
    color: var(--tg-theme-text-color);
    border: var(--tg-theme-text-color);
    padding: 10px;
    font-size: 16px;
    text-align: center;
}
.scanerselecteventtext {
    font-size: 16px;
    font-weight: bold;
    color: var(--tg-theme-text-color);
    margin-bottom: 10px;
    text-align: center;

}

.screenGood {
    box-shadow: none;
    background-color: rgba(87, 255, 87, 0.582);
}

.screenBad {
    box-shadow: none;
    background-color: rgba(255, 87, 87, 0.582);
}

#qr-shaded-region {
    border-width: 50px !important;
}

video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto !important;
    min-width: 100%;
    max-width: 100%;
    transform: translate(-50%, -50%);
}

.start-button {
    background-color: rgb(0, 162, 255);
    border: 0;
    border-radius: 7px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    /* color: var(--tg-theme-text-color); */
    width: 100%;
    padding: 10px;
}

.qr-message {
    color: black;
}