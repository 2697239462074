.Modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ModalData {
    width: 80vw;
    background-color: var(--tg-theme-bg-color);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    color: var(--tg-theme-text-color);
}

.ModalData h3 {
    margin-bottom: 30px;
    text-align: center;
}
.ModalData button {
    padding: 15px 20px;
    border: 0;
    outline: none;
    border-radius: 10px;
    font-weight: bold;
}

.dangerModal {
    border: 2px solid rgba(255, 166, 0, 0.781);
}

.okModal {
    border: 2px solid rgba(6, 177, 0, 0.781);
}

.devModal {
    font-size: 8px !important;
    font-weight: normal !important;
    border: 2px solid rgba(136, 0, 177, 0.781);
}
.errorModal {
    border: 2px solid rgba(177, 0, 29, 0.781);
}