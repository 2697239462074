.zxcGif {
    background-color: rgba(236, 236, 236, 0.658);
    max-width: 150px;
    max-height: 150px;
    border-radius: 50% 10px 10px 50%;
    padding: 20px;
}
.zxc {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-radius: 10px;
}
.author {
    display: flex;
}
.authorData {
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.tgLink {
    color: #0285ff;
    font-weight: bold;
}
.bottomAuthorData {
    display: flex;
}
.socMediaIcons {
    width: 35px;
    margin-right: 10px;
}
.gitIcon {
    background-color: white;
    border-radius: 50%;
}