.QRCode {
    width: 100%;
    max-width: 200px;
    height: 100%;
}
.qrLoading {
    display: flex;
    justify-content: center;
}
.QRBlockData {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.QRBlockDataTitle {
    margin-bottom: 5px;
}
.QRCodeBlock {
    margin-top: 10px;
    margin-bottom: 20px;
}
.QRMemberCardID {
    opacity: 0.5;
}
.QRCodeBlock {
    background-color: white;
    width: fit-content;
    height: fit-content;
    padding: 20px;
    border-radius: 15px;
}
.QRMemberData {
    font-weight: bold;
    margin-bottom: 10px;
}

.sendQRToChat {
    margin-top: 15px;
    border: 0;
    outline: none;
    border-radius: 10px;
    padding: 10px;
}

.succesMessage {
    margin-top: 10px;
    color: green;
}
.QRPrompt {
    font-size: 12px;
    font-weight: normal;
    opacity: 0.6;
}