.devmessage {
    padding: 20px;
}

.devmessage h2 {
    text-align: center;
}

.checkimg {
    max-width: 100px;
}
.checkblock {
    display: flex;
    justify-content: space-between;
}