.header-wraper {
    padding: 0 20px;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
}

.username {
    font-weight: bold;
    text-decoration: underline;
}

.header_close-butt {
    background-color: rgb(255, 64, 64);
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 15px;
    border: 0;
    transition: 0.5s;
}
.header_close-butt:hover {
    opacity: 0.7;
}
.Logo {
    background-color: black;
    border-radius: 50%;
    padding: 5px;
    max-width: 75px;
}
.BotNameAndUser {
    padding-left: 10px;
    width: 100%;
}
.BotNameAndUser h1 {
    font-size: 15px;
    color: var(--tg-theme-text-color);
    margin-bottom: 3px;
}